const api = {
  _getReq(url, params) {
    const controller = new AbortController();
    const signal = controller.signal;
    url += '?';
    for (let key in params) {
      url += `${key}=${params[key]}`;
    }
    let apiCall = fetch(url, { signal })
    .then((response) => response.ok ? response.json() : Promise.reject(response.status));

    apiCall.abort = () => controller.abort();
    apiCall.signal = signal;
    return apiCall;
  },
  searchFilms(term) {
    return this._getReq(`https://swapi.co/api/films`, { search: term });
  }
}

export default api;
