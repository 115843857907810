import React, { useContext, useState, useRef } from 'react';
import {
  StarWarsContext,
  StarWarsActions,
  StarWarsSelectors
} from 'state/starwars.js';

const StarWarsMovie = (props) => {
  let [ searchTerm, setSearchTerm ] = useState("");
  let { state } = useContext(StarWarsContext);

  let films = StarWarsSelectors.getFilms(state);
  let error = StarWarsSelectors.getError(state);
  let isLoading = StarWarsSelectors.getIsLoading(state);

  let timer = useRef(null);
  let timerLength = useRef(200);

  let onChange = (e) => {
    clearTimeout(timer.current);
    let term = e.target.value.trimStart();
    setSearchTerm(term);
    timer.current = setTimeout(() => {
      StarWarsActions.search(term);
    }, timerLength.current);
  }

  return (
    <div>
      <input placeholder="Search Films" type="text" onChange={onChange} value={searchTerm} />
      { error && <p>{error}</p>}
      {
        isLoading ? <p>Loading films...</p>
                  : films.map((film, index) => {
                      return (
                        <div key={index}>
                          <p>{film.title}</p>
                        </div>
                      )
                    })
      }
    </div>
  )
}

export default StarWarsMovie;
