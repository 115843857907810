import React, { useReducer, useContext } from 'react';
import api from 'api';

let defaultState = {
  films: [],
  errorMessage: "",
  isLoading: false,
};

export const StarWarsContext = React.createContext(defaultState);

export const StarWarsActions = {
  dispatch: null,
  // default function so we don't have to check if it's set before calling
  _abortSearch: () => {},
  search: async function(term) {
    this._abortSearch();
    let apiCall = api.searchFilms(term);
    this._abortSearch = apiCall.abort;
    this.setIsLoading(true);
    try {
      let json = await apiCall;
      this.storeFilms(json.results);
      this.setIsLoading(false);
    } catch {
      if (!apiCall.signal.aborted) {
        this.setSearchError("Couldn't find anything.");
        this.setIsLoading(false);
      }
    }
  },
  storeFilms: function(films) {
    this.dispatch({ type: 'storeFilms', films });
  },
  setSearchError: function(message) {
    this.dispatch({ type: 'setSearchError', message });
  },
  setIsLoading: function(isLoading) {
    this.dispatch({ type: 'setIsLoading', isLoading });
  },
}

export const StarWarsSelectors = {
  getFilms: (state) => {
    return state.films;
  },
  getError: (state) => {
    return state.errorMessage;
  },
  getIsLoading: (state) => {
    return state.isLoading;
  }
}

export const StarWarsProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case 'storeFilms': {
        const newState = {
          ...state,
          films: action.films,
          error: "",
        }
        return newState;
      }
      case 'setSearchError': {
        const newState = {
          ...state,
          films: [],
          errorMessage: action.message
        }
        return newState;
      }
      case 'setIsLoading': {
        const newState = {
          ...state,
          isLoading: action.isLoading
        }
        return newState;
      }
      default: {
        return state;
      }
    };
  }, defaultState);

  StarWarsActions.dispatch = dispatch;

  return (
    <StarWarsContext.Provider value={{ state }}>
      {children}
    </StarWarsContext.Provider>
  );
}
