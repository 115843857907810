import React from 'react';
import logo from 'images/logo.svg';
import style from  'style/App.module.scss';
import { StarWarsProvider } from 'state/starwars.js';
import StarWarsMovie from 'components/StarWarsMovie.js';

const App = () => {
  return (
    <StarWarsProvider>
      <div data-testid="app" className={style.App}>
        <StarWarsMovie />
      </div>
    </StarWarsProvider>
  );
}

export default App;
